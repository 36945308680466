<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <h3 class="text-center">Seguimiento de Roadmap</h3>
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <form>
              <div class="d-flex justify-space-around mt-5">
                <div class="row">
                  <div class="col-3">
                    <v-select
                      :items="combos.stages"
                      item-value="id"
                      item-text="name"
                      label="Etapa"
                      v-model="form.idStage"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-3">
                    <v-select
                      :items="combos.activities"
                      item-value="id"
                      item-text="name"
                      label="Actividad"
                      v-model="form.idActivity"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-2">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dateTracked"
                          label="Fecha Registro"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.dateTracked" @input="menu = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-2">
                    <v-select
                      label="Avance Real"
                      :items="combos.percentages"
                      item-value="id"
                      item-text="name"
                      v-model="form.idPercentage"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-2 mt-3">
                    <v-btn
                      outlined
                      color="indigo lighten-2"
                      class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                      @click="saveTrack"
                    >
                      <div v-if="!loading.buttonSaveTrack">
                        <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                        Agregar
                      </div>
                      <div v-else>Loading....</div>
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-alert v-model="alertBox.on" border="right" colored-border type="error" elevation="2" class="ml-2 mr-2">
                {{ alertBox.message }}
              </v-alert>
            </form>
            <v-divider></v-divider>
            <h4 class="text-center mt-10 font-weight-regular">Listado de Seguimiento Roadmap</h4>
            <v-simple-table v-if="trackTable.length > 0" class="table mt-5">
              <template>
                <thead>
                  <tr>
                    <th class="text-left" scope="col" width="10%">ID</th>
                    <th class="text-left" scope="col" width="25%">Etapa</th>
                    <th class="text-left" scope="col" width="30%">Actividad</th>
                    <th class="text-left" scope="col" width="10%">Porcentaje</th>
                    <th class="text-left" scope="col" width="15%">Fecha de Registro</th>
                    <th class="text-left" scope="col" width="10%" nowrap></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="trackTable.length > 0"
                    v-for="(entry, i) in trackTable"
                    :key="i"
                    :class="entry.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td width="10%">{{ entry.idTrackActivityPlanification }}</td>
                    <td width="25%">{{ entry.stageName }}</td>
                    <td width="30%">
                      {{ entry.activityName }}
                    </td>
                    <td width="10%">
                      <v-select
                        :items="combos.percentages"
                        item-value="id"
                        item-text="name"
                        v-model="entry.idPercentage"
                        outlined
                      ></v-select>
                    </td>
                    <td width="15%">
                      <v-menu
                        ref="menu"
                        v-model="entry.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.dateTracked"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="entry.dateTracked"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center buttons" width="10%">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            @click="updateActivity(entry)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteTrackActivityPlanification(entry.idTrackActivityPlanification)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-alert v-else class="text-center mt-8" success shaped outlined color="deep-orange" icon="mdi-fire">
              No registra seguimiento de planficación. Registre para que aparezcan datos.</v-alert
            >

            <div class="d-flex mt-12 justify-space-around">
              <div class="row">
                <div class="col-12 text-center justify-center pa-md-5 mx-lg-auto">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="indigo lighten-2"
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text">save</v-icon>
                    Guardar
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PlanificationService from "@/services/pmo/planification/planificationService";
import AdminService from "@/services/pmo/admin/adminService";

export default {
  data: (vm) => {
    return {
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ),
      trackTable: [],
      combos: {
        stages: [],
        activities: [],
        percentages: [],
      },
      form: {
        idActivity: 0,
        idStage: 0,
        dateTracked: "",
        idPercentage: 0,
      },
      dialog: false,
      alertBox: {
        on: false,
        message: "",
      },
      menu: "",
      loading: {
        buttonSaveTrack: false,
      },
      activeClass: "is-visible",
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    form: {
      handler(form) {
        PlanificationService.getComboActivitiesByStage(form.idStage)
          .then((data) => {
            this.combos.activities = data;
          })
          .catch((err) => console.dir(err));
      },
      deep: true,
    },
  },
  methods: {
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
      this.$emit("reloadPlanificationCharts");
    },
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadPlanificationCharts");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveTrack() {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        idActivity: this.form.idActivity,
        idStage: this.form.idStage,
        dateTracked: this.form.dateTracked,
        idPercentage: this.form.idPercentage,
      };

      this.loading.buttonSaveTrack = true;

      PlanificationService.saveTrack(request)
        .then(() => {
          PlanificationService.getTrackTable(this.project)
            .then((data) => {
              this.trackTable = data;
            })
            .catch((err) => console.dir(err));
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alertBox.message = message;
          this.alertBox.on = true;
          console.dir(err);
        })
        .finally(() => {
          this.reload();
          this.loading.buttonSaveTrack = false;
          setTimeout(() => (this.alertBox.on = false), 4000);
          this.clearForm();
          this.$emit("reloadPlanificationCharts");
        });
    },
    clearForm() {},
    deleteTrackActivityPlanification(idActivityTrackPlanification) {
      PlanificationService.deleteTrackActivityPlanification(idActivityTrackPlanification)
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          PlanificationService.getTrackTable(this.project)
            .then((data) => {
              this.trackTable = data;
            })
            .catch((err) => console.dir(err));

          setTimeout(() => (this.alertBox.on = false), 4000);
        });
    },
    updateActivity(item) {
      item.projectId = this.project.projectId;
      item.enterpriseId = this.project.enterpriseId;
      PlanificationService.updateTrack(item)
        .then(() => {
          PlanificationService.getTrackTable(this.project)
            .then((data) => {
              this.trackTable = data;
            })
            .catch((err) => console.dir(err));
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alertBox.message = message;
          this.alertBox.on = true;
          console.dir(err);
        })
        .finally(() => {
          this.reload();
          this.loading.buttonSaveTrack = false;
          setTimeout(() => (this.alertBox.on = false), 4000);
          this.clearForm();
          this.$emit("reloadPlanificationCharts");
          this.saveItem(item);
        });
    },
    reload() {
      PlanificationService.getStagesForCombo(this.project)
        .then((data) => {
          this.combos.stages = data;
        })
        .catch((err) => console.dir(err));

      PlanificationService.getTrackTable(this.project)
        .then((data) => {
          this.trackTable = data;
          console.log("trackTable: ", data);
        })
        .catch((err) => console.dir(err));

      AdminService.getComboPercentages()
        .then((data) => {
          this.combos.percentages = data;
        })
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    // TODO: Obtener datos del proyecto
    this.clearForm();

    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.reload();
  },
};
</script>
<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
  white-space: nowrap;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
</style>
