<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="tway-violet--text"> mdi-cog-outline </v-icon>
          CONFIGURACIÓN
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <h3 class="text-center">Configuración Roadmap</h3>
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card elevation="1" shaped>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div class="my-2 text-subtitle-1">
                    Duración estimada
                    {{ this.projectObj.period }}
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- ETAPAS -->
            <form>
              <div class="d-flex justify-space-around mt-1">
                <div class="row">
                  <div class="col-12 mt-8">
                    Etapas
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-3">
                    <v-text-field label="Etapa" type="text" v-model="stage.name" outlined />
                  </div>
                  <!-- Fecha Inicio Etapa -->
                  <div class="col-3">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="stage.dateStart"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="stage.dateStart" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <!-- Fecha de Termino Etapa -->
                  <div class="col-3">
                    <v-menu
                      v-model="menu4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :min="stage.dateStart"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="stage.dateEnd"
                          label="Fecha Término"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :min="stage.dateStart"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="stage.dateEnd"
                        @input="menu4 = false"
                        :min="stage.dateStart"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-3 text-center d-flex justify-center pa-md-6">
                    <v-btn
                      outlined
                      color="indigo lighten-2"
                      class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                      @click.stop="saveStage"
                    >
                      <div>
                        <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                        <span v-if="!loading.saveStage">Agregar</span>
                        <span v-else>Guardando...</span>
                      </div>
                    </v-btn>
                  </div>
                </div>
              </div>
            </form>
            <h4 class="text-center mt-10 font-weight-regular">Listado de Configuración Etapas</h4>
            <!-- TABLA ETAPA -->
            <v-alert
              v-model="alert.saveStage"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.saveStage }}
            </v-alert>
            <v-simple-table class="table mt-5">
              <template>
                <thead>
                  <tr>
                    <th class="text-left" scope="col">ID</th>
                    <th class="text-left" scope="col">Etapa</th>
                    <th class="text-left" scope="col">Inicio</th>
                    <th class="text-left" scope="col">Término</th>
                    <th class="text-left" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="etapas.length > 0"
                    v-for="(entry, i) in etapas"
                    :key="i"
                    :class="entry.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <th scope="row">{{ entry.stageId }}</th>
                    <td>
                      <v-text-field v-model="entry.stageName" outlined> </v-text-field>
                    </td>
                    <td>
                      <v-menu
                        ref="menu"
                        v-model="entry.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.startDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="entry.startDate"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-menu
                        ref="menu"
                        v-model="entry.menu2"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.endDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="entry.endDate"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="save" v-bind="attrs" v-on="on" @click="updateStage(entry)">
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteStage(entry.stageId)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- ACTIVIDADES -->
            <form>
              <div class="d-flex mt-4 justify-start">
                <div class="row">
                  <div class="col-12 mt-8">
                    Actividades
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-4">
                    <v-select
                      :items="combos.selectEtapas"
                      item-value="id"
                      item-text="name"
                      label="Etapa"
                      v-model="activity.stageSelected"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-4">
                    <v-select
                      :items="combos.responsible"
                      item-value="id"
                      item-text="name"
                      label="Responsable"
                      v-model="activity.responsableSelected"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-4">
                    <v-text-field type="text" label="Actividad" v-model="activity.name" outlined />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-space-around">
                <div class="row">
                  <div class="col-4">
                    <v-menu
                      v-model="menu5"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="activity.dateStart"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="activity.dateStart" @input="menu5 = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-4">
                    <v-menu
                      v-model="menu6"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :min="activity.dateStart"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="activity.dateEnd"
                          label="Fecha Término"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :min="activity.dateEnd"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="activity.dateEnd"
                        @input="menu6 = false"
                        :min="activity.dateStart"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-4 text-center d-flex justify-center pa-md-6 mx-lg-auto">
                    <v-btn
                      outlined
                      color="indigo lighten-2"
                      class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                      @click.stop="saveActivity"
                    >
                      <div>
                        <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                        <span v-if="!loading.saveActivity">Agregar</span>
                        <span v-else>Guardando...</span>
                      </div>
                    </v-btn>
                  </div>
                </div>
              </div>
            </form>
            <h4 class="text-center mt-10 font-weight-regular">Listado de Configuración Actividades</h4>
            <!-- Tabla Actividad -->
            <v-alert
              v-model="alert.saveActivity"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.saveActivity }}
            </v-alert>
            <v-simple-table class="table mt-5">
              <template>
                <thead>
                  <tr>
                    <th class="text-left" width="5%" scope="col">ID</th>
                    <th class="text-left" scope="col">Etapa</th>
                    <th class="text-left" width="20%" scope="col">Actividad</th>
                    <th class="text-left" width="10%" scope="col">Responsable</th>
                    <th class="text-left" width="13%" scope="col">Inicio</th>
                    <th class="text-left" width="13%" scope="col">Término</th>
                    <th class="text-left" width="15%" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="activities.length > 0"
                    v-for="(entry, i) in activities"
                    :key="i"
                    :class="entry.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td>{{ entry.activityId }}</td>
                    <td>
                      <v-select
                        :items="combos.selectEtapas"
                        item-value="id"
                        item-text="name"
                        v-model="entry.idStage"
                        outlined
                      >
                      </v-select>
                    </td>
                    <td>
                      <v-text-field v-model="entry.activityName" outlined> </v-text-field>
                    </td>
                    <td>
                      <v-select
                        :items="combos.responsible"
                        item-value="id"
                        item-text="name"
                        v-model="entry.idResponsible"
                        outlined
                      >
                      </v-select>
                    </td>
                    <td>
                      <v-menu
                        ref="menu"
                        v-model="entry.menu3"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.startDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="entry.startDate"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-menu
                        ref="menu"
                        v-model="entry.menu4"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.endDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="entry.endDate"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            v-bind="attrs"
                            v-on="on"
                            @click="updateActivity(entry)"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteActivity(entry.activityId)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex mt-12 justify-space-around">
              <div class="row">
                <div class="col-12 text-center justify-center pa-md-5 mx-lg-auto">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="indigo lighten-2"
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text">mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <confirmation ref="confirm" :message="dialogMessage.title" :message-body="dialogMessage.body"></confirmation>
  </div>
</template>

<script>
import PlanificationService from "@/services/pmo/planification/planificationService";
import Confirmation from "../utils/ConfirmationDialog.vue";

export default {
  components: { Confirmation },
  data: (vm) => {
    return {
      projectObj: {},
      menu6: false,
      on: false,
      dialogMessage: {
        title: "",
        body: "",
      },
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ),
      loading: {
        saveStage: false,
        saveActivity: false,
      },
      alert: {
        saveStage: false,
        saveActivity: false,
      },
      messageError: {
        saveStage: "",
        saveActivity: "",
      },
      activity: {
        stageSelected: 0,
        name: "",
        dateStart: "",
        dateEnd: "",
        responsableSelected: 0,
      },
      stage: {
        name: "",
        dateStart: "",
        dateEnd: "",
      },
      dialog: false,
      menu: false,
      modal: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      startDateProposal: "",
      endDateProposal: "",
      etapas: [],
      combos: {
        selectEtapas: [],
        responsible: [],
      },
      setup: null,
      activities: [],
      activeClass: "is-visible",
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    sortedList: function () {
      return this.allScores.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    sortedList2: function () {
      return this.allScor.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    computedStartDateProposal() {
      return this.formatDate(this.setup.startDateProposal);
    },
    computedStageDateStart() {
      return this.formatDate(this.stage.dateStart);
    },
    computedStageDateEnd() {
      return this.formatDate(this.stage.dateEnd);
    },
    computedDateFormatted5() {
      return this.formatDate(this.activity.dateStart);
    },
    computedDateFormatted6() {
      return this.formatDate(this.activity.dateEnd);
    },
    computedEndDateProposal(val) {
      return val;
    },
  },
  methods: {
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadPlanificationCharts");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveProject() {},
    saveStage() {
      let frm = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        name: this.stage.name,
        startDate: this.stage.dateStart,
        endDate: this.stage.dateEnd,
      };

      this.loading.saveStage = true;

      PlanificationService.saveStage(frm)
        .then(() => {
          this.loading.saveStage = false;
          this.reload();
          this.clearForm();
        })
        .catch((err) => {
          this.loading.saveStage = false;
          let message = err.response.data.message;
          this.alert.saveStage = true;
          this.messageError.saveStage = message;
          console.dir(err);
        });
    },
    updateStage(entry) {
      this.saveItem(entry);
      let frm = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        name: entry.stageName,
        startDate: entry.startDate,
        endDate: entry.endDate,
        index: entry.stageId,
      };

      PlanificationService.saveStage(frm)
        .then(() => {
          this.loading.saveStage = false;
          this.reload();
          this.clearForm();
        })
        .catch((err) => {
          this.loading.saveStage = false;
          let message = err.response.data.message;
          this.alert.saveStage = true;
          this.messageError.saveStage = message;
          console.dir(err);
        });
    },
    saveActivity() {
      let frm = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        stageId: this.activity.stageSelected,
        responsibleId: this.activity.responsableSelected,
        activityName: this.activity.name,
        startDate: this.activity.dateStart,
        endDate: this.activity.dateEnd,
      };

      this.loading.saveActivity = true;

      PlanificationService.saveActivityWithStageId(frm)
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveActivity = true;
          this.messageError.saveActivity = message;
          console.dir(err);
        })
        .finally(() => {
          this.cleanFormActivity();

          PlanificationService.getActivitiesTableConfiguration(this.project)
            .then((data) => {
              this.activities = data;
            })
            .catch((err) => console.dir(err));

          this.loading.saveActivity = false;
          setTimeout(() => (this.alert.saveActivity = false), 4000);
        });
    },
    updateActivity(entry) {
      this.saveItem(entry);
      let frm = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        stageId: entry.idStage,
        responsibleId: entry.idResponsible,
        activityName: entry.activityName,
        index: entry.activityId,
        startDate: entry.startDate,
        endDate: entry.endDate,
      };
      PlanificationService.saveActivityWithStageId(frm)
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveActivity = true;
          this.messageError.saveActivity = message;
          console.dir(err);
        })
        .finally(() => {
          this.cleanFormActivity();

          PlanificationService.getActivitiesTableConfiguration(this.project)
            .then((data) => {
              this.activities = data;
            })
            .catch((err) => console.dir(err));

          this.loading.saveActivity = false;
          setTimeout(() => (this.alert.saveActivity = false), 4000);
        });
    },
    clearForm() {
      this.stage.name = "";
      this.stage.dateStart = "";
      this.stage.dateEnd = "";
    },
    cleanFormActivity() {
      this.activity.stageSelected = 0;
      this.activity.responsableSelected = 0;
      this.activity.name = "";
      this.activity.dateStart = "";
      this.activity.dateEnd = "";
    },
    deleteStage(stageId) {
      this.dialogMessage.title = "¿Está seguro de eliminar esta etapa?";
      this.dialogMessage.body = "Esta acción eliminará todas las actividades asociadas a esta etapa";
      console.dir(this.$refs);

      const veamos = this.$refs.confirm.open();

      veamos.then(() => {
        PlanificationService.deleteStage(stageId)
          .catch((err) => console.dir(err))
          .finally(() => {
            // La tabla de etapas guardadas.
            PlanificationService.getStagesTableConfiguration(this.project)
              .then((data) => {
                this.etapas = data;
              })
              .catch((err) => console.dir(err));

            // Combo de etapas
            PlanificationService.getStagesForCombo(this.project)
              .then((data) => {
                this.combos.selectEtapas = data;
              })
              .catch((err) => console.dir(err));

            PlanificationService.getActivitiesTableConfiguration(this.project)
              .then((data) => {
                this.activities = data;
              })
              .catch((err) => console.dir(err));
          });
      });
    },
    deleteActivity(id) {
      PlanificationService.deleteActivity(id)
        .then(() => {
          PlanificationService.getActivitiesTableConfiguration(this.project)
            .then((data) => {
              this.activities = data;
            })
            .catch((err) => console.dir(err));
        })
        .catch((err) => console.dir(err));
    },
    reload() {
      PlanificationService.getStagesForCombo(this.project)
        .then((data) => {
          this.combos.selectEtapas = data;
        })
        .catch((err) => console.dir(err));

      // El combo de responsables para una actividad
      PlanificationService.getComboResponsible(this.project)
        .then((data) => {
          this.combos.responsible = data;
        })
        .catch((err) => console.dir(err));

      // La tabla de etapas guardadas.
      PlanificationService.getStagesTableConfiguration(this.project)
        .then((data) => {
          this.etapas = data;
        })
        .catch((err) => console.dir(err));

      PlanificationService.getActivitiesTableConfiguration(this.project)
        .then((data) => {
          this.activities = data;
          console.log("actividades", data);
        })
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    this.clearForm();
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;
    this.projectObj = JSON.parse(sessionStorage.getItem("pmoProject"));

    this.reload();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.modalSystem .edit_row .v-input__prepend-outer {
  display: none;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
</style>
