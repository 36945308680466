<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <configuration class="" @reloadPlanificationCharts="reloadCharts"></configuration>
      <seguimiento class="ml-5" @reloadPlanificationCharts="reloadCharts"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Roadmap</h3>
    <div class="mx-auto" max-width="auto">
      <div class="resumen">
        <div class="resumen-detail">
          <div class="mb-3 tway-violet--text">
            <p style="font-size: 18px">Resumen</p>
          </div>
          <div class="resumen-detail-item">
            <div>Avance Real:</div>
            <div class="resumen-detail-item-data">
              <div>
                {{ summaryPlanification.data.realPercentageProgress }}
                <img width="15%" :src="require('@/assets/img/pmo/roadmap.svg')" />
              </div>
              <div></div>
            </div>
          </div>
          <div class="resumen-detail-item">
            <div>Avance Esperado:</div>
            <div class="resumen-detail-item-data">
              <div>
                {{ summaryPlanification.data.wantedPercentageProgress }}
                <img width="15%" :src="require('@/assets/img/pmo/roadmap.svg')" />
              </div>
              <div></div>
            </div>
          </div>
          <div class="resumen-detail-item">
            <div>Desviación:</div>
            <div class="resumen-detail-item-data">
              <div>
                {{ summaryPlanification.data.deviationPercentageProgress }}
                <img
                  v-if="summaryPlanification.data.deviationPercentageProgressNumber > 0"
                  width="15%"
                  :src="require('@/assets/img/pmo/up-arrow-green.svg')"
                />
                <img
                  v-if="summaryPlanification.data.deviationPercentageProgressNumber < 0"
                  width="15%"
                  :src="require('@/assets/img/pmo/down-arrow-red.svg')"
                />
              </div>
            </div>
          </div>
          <div class="resumen-detail-item">
            <div>Inicio Real:</div>
            <div class="resumen-detail-item-data">
              <div>
                {{ summaryPlanification.data.globalRealStartDateSummary }}
              </div>
              <div></div>
            </div>
          </div>
          <div class="resumen-detail-item">
            <div>Término Planificado:</div>
            <div class="resumen-detail-item-data">
              <div>
                {{ summaryPlanification.data.globalRealEndDatePlanificatedSummary }}
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <!-- CHART SUMMARY -->
        <div class="resumen-bar">
          <VueApexCharts
            :series="summaryPlanification.series"
            :options="summaryPlanification.options"
            type="bar"
            height="150"
            class="full"
          />
        </div>
      </div>
      <div v-if="messages.isChartSummary">
        <v-alert class="text-center mt-8" success shaped outlined color="deep-orange" icon="mdi-fire">
          {{ messages.chartSummary }}
        </v-alert>
      </div>

      <div class="d-flex flex-row align-center">
        <div>
          <div></div>
        </div>
      </div>
    </div>
    <v-card class="mx-auto" max-width="auto" style="border-radius: 20px">
      <div class="mx-4 mt-5 pt-5 tway-violet--text padding-fix">
        <p>Roadmap de Actividades</p>
      </div>
      <div v-if="charts.timeLine.serie" class="pb-4">
        <timeline-chart :serie="charts.timeLine.serie"></timeline-chart>
      </div>
      <div v-else>Cargando...</div>
    </v-card>
    <div class="box-table">
      <div class="box-table-title tway-violet--text">
        Seguimiento de Actividades
        <v-icon color="purple" @click="reloadAccomplishmentTable"> mdi-reload </v-icon>

        <div v-if="messages.isAccomplishmentTableEmpty">
          <v-icon color="purple"> mdi-content-save </v-icon>
        </div>
      </div>
      <div class="multi-table-content">
        <div class="d-flex fontSizeUl flex-column ml-4 mt-2 box-tabla-etapa"></div>
      </div>
      <table class="table-planification" style="width: 100%">
        <tr>
          <th class="first-th" colspan="2"></th>
          <th class="first-th" colspan="3">AVANCE %</th>
          <th class="first-th" colspan="3">INICIO</th>
          <th class="first-th" colspan="3">TÉRMINO</th>
          <th class="first-th">NOMBRE</th>
        </tr>
        <tr>
          <th class="second-th" style="width: 19%">Etapa</th>
          <th class="second-th" style="width: 19%">Actividad</th>
          <th class="second-th" style="width: 5%">Planificado</th>
          <th class="second-th" style="width: 5%">Real</th>
          <th class="second-th" style="width: 6%">Desviación</th>
          <th class="second-th" style="width: 7%">Planificado</th>
          <th class="second-th" style="width: 7%">Real</th>
          <th class="second-th" style="width: 5%">Desviación (Días)</th>
          <th class="second-th" style="width: 7%">Planificado</th>
          <th class="second-th" style="width: 7%">Real</th>
          <th class="second-th" style="width: 5%">Desviación (Días)</th>
          <th class="second-th" style="width: 8%">Responsable</th>
        </tr>
        <tr v-for="(entry, i) in accomplishments" :key="i">
          <td style="text-align: left !important">{{ entry.stageName }}</td>
          <td style="text-align: left !important">{{ entry.activityName }}</td>
          <!-- PROGRESS -->
          <td class="left-border-td">{{ entry.progressPlanificated }}</td>
          <td>
            {{ entry.progressReal }}
          </td>
          <td class="right-border-td">
            {{ entry.progressDeviation }} &#37;
            <img
              v-if="entry.progressDeviation > 0"
              width="10px"
              :src="require('@/assets/img/pmo/up-arrow-green.svg')"
            />
            <img
              v-if="entry.progressDeviation < 0"
              width="10px"
              :src="require('@/assets/img/pmo/down-arrow-red.svg')"
            />
            <img v-if="entry.progressDeviation == 0" width="10px" :src="require('@/assets/img/pmo/roadmap.svg')" />
          </td>
          <!-- START -->
          <td class="left-border-td">{{ entry.startPlanificated }}</td>
          <td>{{ entry.startReal }}</td>
          <td class="right-border-td">
            {{ entry.startDaysDeviation }}

            <template v-if="entry.startDaysDeviation">
              <img
                v-if="entry.startDaysDeviation > 0"
                width="10px"
                :src="require('@/assets/img/pmo/up-arrow-green.svg')"
              />
              <img
                v-if="entry.startDaysDeviation < 0"
                width="10px"
                :src="require('@/assets/img/pmo/down-arrow-red.svg')"
              />
              <img v-if="entry.startDaysDeviation == 0" width="10px" :src="require('@/assets/img/pmo/roadmap.svg')" />
            </template>
          </td>
          <!-- END -->
          <td class="left-border-td">{{ entry.endPlanificated }}</td>
          <td>{{ entry.endReal }}</td>
          <td class="right-border-td">
            {{ entry.endDaysDeviation }}
            <template v-if="entry.endDaysDeviation">
              <img
                v-if="entry.endDaysDeviation > 0"
                width="10px"
                :src="require('@/assets/img/pmo/up-arrow-green.svg')"
              />
              <img
                v-if="entry.endDaysDeviation < 0"
                width="10px"
                :src="require('@/assets/img/pmo/down-arrow-red.svg')"
              />
              <img v-if="entry.endDaysDeviation == 0" width="10px" :src="require('@/assets/img/pmo/roadmap.svg')" />
            </template>
          </td>
          <td class="right-border-td">{{ entry.nameResponsible }}</td>
        </tr>
      </table>
      <div v-if="messages.isAccomplishmentTableEmpty">
        <v-alert class="text-center mt-8" success shaped outlined color="deep-orange" icon="mdi-fire">
          {{ messages.accomplishmentTable }}
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import TimelineChart from "@/components/charts/pmo/apex/TimeLineChart.vue";
import Configuration from "../../components/pmo/planification/configuration.vue";
import Seguimiento from "../../components/pmo/planification/seguimiento.vue";
import PlanificationService from "@/services/pmo/planification/planificationService";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    // StackedBars100,
    TimelineChart,
    Configuration,
    Seguimiento,
    VueApexCharts,
  },
  data() {
    return {
      messages: {
        isAccomplishmentTableEmpty: false,
        accomplishmentTable: "",
        isChartSummary: false,
        chartSummary: "",
      },
      summaryPlanification: {
        data: Object,
        series: [],
        options: {
          chart: {
            type: "bar",
            height: 180,
            width: "100%",
            stacked: true,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 0,
            colors: ["#fff"],
            curve: "smooth",
            lineCap: "round",
            dashArray: 0,
          },
          title: {
            text: "",
          },
          xaxis: {
            categories: [""],
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "%";
              },
            },
          },
          fill: {
            opacity: 1,
          },
          colors: ["#16C62E", "#D7263D", "#7319D5", "#4A148C"],
          legend: {
            position: "left",
            horizontalAlign: "left",
            offsetX: 0,
            offsetY: 35,
          },
        },
        series2: [
          {
            name: "Promotores",
            data: [49],
          },
          {
            name: "Neutros",
            data: [12],
          },
          {
            name: "Detractores",
            data: [13],
          },
        ],
        options2: {
          chart: {
            type: "bar",
            height: 150,
            stacked: true,
            //stackType: '100%'
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            text: "",
          },
          xaxis: {
            categories: ["Agosto"],
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
          },
          colors: ["#008F39", "#E5Be01", "#D7263D"],
        },
      },
      accomplishments: [],
      charts: {
        fontFamily: "Josefin Sans, Arial, sans-serif",
        timeLine: {
          serie: null,
        },
        stacked: {
          serie: [],
        },
      },
      serieStacked: [],
    };
  },
  methods: {
    reloadAccomplishmentTable() {
      PlanificationService.getAccomplishmentTable(this.project)
        .then((data) => {
          this.accomplishments = data;
        })
        .catch((err) => console.dir(err))
        .finally(() => {});
    },
    colorCelda(v) {
      return v.qualifier == -1 ? "rojo" : "azul";
    },
    reloadCharts() {
      PlanificationService.getChartSummary(this.project)
        .then((data) => {
          this.summaryPlanification.series = data.series;
          this.summaryPlanification.data = data.data;
          this.messages.isChartSummary = false;
          console.log("plan data: ", data.data);
        })
        .catch((err) => {
          this.messages.isChartSummary = true;
          this.messages.chartSummary = err.response.data.message;
          console.log("========");
          console.log("Alerta Resumen de Planificacion");
          console.log("-------");
          console.dir(err.response.data.message);
          console.dir(err);
          console.log("========");
        });

      PlanificationService.getAccomplishmentTable(this.project)
        .then((data) => {
          this.accomplishments = data;
          this.messages.isAccomplishmentTableEmpty = false;
        })
        .catch((err) => {
          this.messages.isAccomplishmentTableEmpty = true;
          this.messages.accomplishmentTable = err.response.data.message;
          console.log("========");
          console.log("Alerta Tabla de Cumplimiento");
          console.log("-------");
          console.dir(err.response.data.message);
          console.dir(err);
          console.log("========");
        })
        .finally(() => {});

      PlanificationService.getChartTrackByMonth(this.project)
        .then((data) => {
          this.charts.timeLine.serie = data;
        })
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.reloadCharts();
  },
};
</script>

<style scoped lang="scss">
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1em;
  text-indent: -0.7em;
}

li::before {
  content: "• ";
  color: red; /* or whatever color you prefer */
}

.fontSizeUl {
  font-size: 10px;
}
.rojo {
  border: 1px solid red;
}
.azul {
  border: 1px solid blue;
}
/*

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: 11px !important;
}

table tr {
  border: solid rgb(170, 164, 164) 0.5px;
}

table,
th {
  border: 1px solid grey;
}

th {
  vertical-align: top;
}
*/
// td:empty:after {
//   content: "\00a0"; /* HTML entity of &nbsp; */
// }

.table-planification {
  width: 100%;
  font-size: 11px;
  text-align: center;
  border: 0.5px solid rgb(185, 185, 185);
  border-collapse: collapse;
  .first-th {
    border: 0.5px solid rgb(185, 185, 185);
    width: 22%;
    height: 30px;
  }
  .first-th:last-child {
    border: 0.5px solid rgb(185, 185, 185);
    width: 10%;
    border-bottom: 0px;
  }
  .second-th {
    border: 0.5px solid rgb(185, 185, 185);
    width: 10%;
    height: 30px;
  }
  .left-border-td {
    border-left: 0.5px solid rgb(185, 185, 185);
  }

  .right-border-td {
    border-right: 0.5px solid rgb(185, 185, 185);
  }

  .left-border-th {
    border-left: 0.5px solid rgb(185, 185, 185);
  }

  .right-border-th {
    border-right: 0.5px solid rgb(185, 185, 185);
  }

  td {
    text-align: right;
    padding-right: 20px;
  }
  .inter-table-th {
    text-align: right;
    padding-right: 20px;
  }
}

.cabecera {
  font-weight: bold;
}
.resumen {
  display: flex;
  width: 100%;
  height: 235px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 5px 0px;
  padding: 10px 20px 0px 20px;
  border-radius: 20px;
}

.resumen > .resumen-detail {
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px !important;
}
.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > .resumen-detail-item-data {
  display: flex;
  width: 55%;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > .resumen-detail-item-data > div:first-child {
  text-align: right;
  width: 60%;
}
.resumen > .resumen-detail > .resumen-detail-item > .resumen-detail-item-data > div {
  width: 0%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 78%;
  height: auto;
  margin-top: 40px;
}
.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}
.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.box-tabla {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px !important;
  justify-content: center;
}

.box-tabla > .box-tabla-etapa {
  margin-right: 20px;
  margin-top: 29px !important;
  padding: 0px !important;
  text-align: center !important;
}
.box-tabla > .box-tabla-tabla {
  margin-left: 20px;
  font-size: 10px !important;
  padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 10px !important;
}

.table-sin-marco {
  border: 0px;
  text-align: center !important;
  border-bottom: 1px solid black;
}
.table-sin-marco {
  border: 0px;
  text-align: center !important;
  border-bottom: 1px solid black;
}

.box-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  padding: 25px 20px 40px 20px;
  font-size: 13px;
  border-radius: 20px;
}
.multi-table-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.table-compliance {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 90%;
  text-align: center;
}

.table-compliance-firts-header,
.table-compliance-second-header,
.table-compliance-content {
  display: flex;
  border: 1px solid black;
  width: 100%;
}
.table-compliance-firts-header div {
  width: 30%;
}

.table-compliance-firts-header div:last-child {
  width: 10%;
}

.table-compliance-second-header div {
  display: flex;
  width: 30%;
  font-size: 10px;
}

.table-compliance-second-header div:last-child {
  display: flex;
  width: 10%;
  font-size: 10px;
}

.table-compliance-second-header div .celda {
  display: flex;
  width: 33%;
  font-size: 10px;
  justify-content: center;
  font-weight: bold;
}

.table-compliance-content {
  border: 0px;
}

.table-compliance-content div {
  display: flex;
  width: 30%;
  font-size: 10px;
  text-align: center !important;
  justify-content: center;
}
.table-compliance-content > .celda-resp {
  display: flex;
  width: 10%;
  font-size: 10px;
  text-align: center !important;
  justify-content: center;
}

.table-compliance-content div .celda {
  display: flex;
  align-content: center;
  width: 33%;
  font-size: 10px;
  text-align: center !important;
  border: 0px;
  justify-content: center;
}

.table-compliance-content div > .celda-etapa {
  font-weight: bold;
}

.line-boder-right {
  border-right: 1px black solid !important;
}
.last-resposable-header {
  border: 0px;
}

.multi-table-content > .box-tabla-etapa {
  margin-right: 20px;
  padding: 0px !important;
  text-align: center !important;
  margin: 0px 15px 0px 25px;
}
.box-table-title {
  display: flex;
  font-size: 20px;
  margin-bottom: 15px;
}
.color-status {
  width: 5px;
  height: 5px;
  border-radius: 50px;
}
.etapa-list > li {
  list-style: none !important;
  display: flex;
  align-items: center;
}
.etapa-list > li > div:first-child {
  margin-right: 9px;
}
.full {
  width: 100%;
  height: fit-content;
}
.padding-fix > p {
  margin: 10px 0px 0px 5px;
}
</style>
