<template>
  <div id="chart">
    <apexchart type="rangeBar" height="450" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    serie: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          fontFamily: "Josefin Sans, Arial, sans-serif",
          height: 650,
          width: 1600,
          type: "rangeBar",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
            autoSelected: "zoom",
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "datetime",
        },
        stroke: {
          width: 0.5,
        },
        fill: {
          type: "solid",
          opacity: 0.6,
        },
        legend: {
          position: "right",
          horizontalAlign: "left",
        },
      },
      series: [],
    };
  },
  watch: {
    serie: function (newVal) {
      this.series = newVal;
    },
  },
  mounted() {
    this.series = this.serie;
  },
};
</script>

<style></style>
